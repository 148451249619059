document.addEventListener('DOMContentLoaded', () => {
  const ele = document.getElementById("pay-type-store")
  if (!ele) return

  const payType = ele.dataset.payType
  if (payType !== 'paypal') return

  document.querySelectorAll('.paypal-button-container').forEach((item) => {
    const id = item.id
    const no = item.dataset.no

    paypal.Buttons({
      createOrder() {
        return fetch(`/orders/new?no=${no}&pay_type=paypal`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => response.json())
        .then((res) => {
          console.log("res is: ", res)
          return res.order.id
        });
      },

      onApprove(data) {
        // This function captures the funds from the transaction.
        return fetch(`/orders/pay_by_paypal?paypal_order_id=${data.orderID}`, {
          method: "GET",
        })
        .then((response) => response.json())
        .then((details) => {
          if (details.code === 200) {
            window.location.href = '/?order_paid=1'
          } else {
            alert('Payment failed!')
          }
        });
      },
    }).render(`#${id}`);
  
  })
});